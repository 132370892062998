import React from "react";
import { graphql } from "gatsby";
import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import { Helmet } from 'react-helmet';

import PageHeader from "../components/showcase-page-header";
import ShowcaseMain from "../components/showcase-main";
import CallToAction from "../components/call-to-action";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query ShowcaseTemplateQuery($id: String!) {
    showcase: sanityShowcase(id: { eq: $id }) {
      id
      title
      categories {
        _key
        showcaseCategory {
          title
          slug {
            current
          }
        }
      }
      partner {
        name
        slug {
          current
        }
        link
        type {
          title
        }
        logo {
          asset {
            extension
            url
            _id
          }
          alt
        }
      }
      client {
        name
        link
        logo {
          asset {
            extension
            url
            _id
          }
          alt
        }
      }
      headerButton {
        text
        url
      }
      thumbnail {
        ...SanityImage
        alt
      }
      industry
      location
      website {
        url
        text
      }
      companySize
      flexibleContent {
        ... on SanityFc2ColumnImage {
          _key
          _type
          imageLeft {
            ...SanityImage
            alt
            caption
          }
          imageRight {
            ...SanityImage
            alt
            caption
          }
        }
        ... on SanityFcContentBlock {
          _key
          _type
          _rawContent
        }
        ... on SanityFcImage {
          _key
          _type
          fullwidth
          image {
            ...SanityImage
            alt
            caption
          }
        }
        ... on SanityFcVideo {
          _key
          _type
          caption
          url
        }
        ... on SanityFcNumbers {
          _key
          _type
          numbers {
            _key
            description
            number
          }
        }
      }
      seo {
        title
        description
        thumbnail {
          asset {
            _id
          }
          alt
        }
      }
    }

    showcaseLanding: sanityShowcaseLanding {
      themePurple
      callToAction {
        title
        _rawDescription
        button {
          blank
          secondaryButton
          text
          url
        }
      }
    }
  }
`;

const ShowcaseTemplate = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  
  const showcaseLanding = (data || {}).showcaseLanding;
  const showcase = (data || {}).showcase;

  const title = showcase.seo?.title || showcase.title;
  const description = showcase.seo?.description;
  const image = showcase.seo?.thumbnail || showcase.thumbnail;
  const autoSiteTitle = !showcase.seo?.title;

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        image={image}
        autoSiteTitle={autoSiteTitle}
      />

      <Helmet>
        <html className={showcaseLanding.themePurple ? "theme-purple" : undefined} lang="en" />
      </Helmet>

      {showcase.title && (<PageHeader title={showcase.title} category={showcase.categories} button={showcase.headerButton} partner={showcase.partner} client={showcase.client} image={showcase.thumbnail} />)}
      {showcase.flexibleContent && (<ShowcaseMain content={showcase.flexibleContent} industry={showcase.industry} location={showcase.location} website={showcase.website} companySize={showcase.companySize} />)}
      {showcaseLanding.callToAction && (<CallToAction {...showcaseLanding.callToAction} />)}
    </Layout>
  );
};

export default ShowcaseTemplate;
