import React from "react";
import Container from "./container";
import FlexibleContent from "../components/flexible-content";

import * as styles from "../styles/components/post-main.module.css";

const ShowcaseMain = (props) => {

  const {
    content,
    industry,
    location,
    website,
    companySize
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.content}>
          {content && (<FlexibleContent items={content} />)}
        </div>
        <div className={styles.sidebar}>
          {industry && (
            <div className={styles.section}>
              <div className={styles.label}>Industry</div>
              <div>{industry}</div>
            </div>
          )}
          {location && (
            <div className={styles.section}>
              <div className={styles.label}>Location</div>
              <div>{location}</div>
            </div>
          )}
          {website && (
            <div className={styles.section}>
              <div className={styles.label}>Website</div>
              <div className="entry-content">
                <a href={website.url} target="_blank" rel="noreferrer">
                  {website.text}
                </a>
              </div>
            </div>
          )}
          {companySize && (
            <div className={styles.section}>
              <div className={styles.label}>Company Size</div>
              <div>{companySize}</div>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
}

export default ShowcaseMain;
