// extracted by mini-css-extract-plugin
export var category = "showcase-page-header-module--category--0a054";
export var client = "showcase-page-header-module--client--966f7";
export var contentWrap = "showcase-page-header-module--contentWrap--82e97";
export var demo = "showcase-page-header-module--demo--a7f65";
export var grid = "showcase-page-header-module--grid--024b2";
export var image = "showcase-page-header-module--image--dd94b";
export var label = "showcase-page-header-module--label--7afaa";
export var logo = "showcase-page-header-module--logo--87320";
export var partner = "showcase-page-header-module--partner--776a7";
export var root = "showcase-page-header-module--root--f9b6a";
export var text = "showcase-page-header-module--text--da319";
export var title = "showcase-page-header-module--title--50fbc";