import React from "react";
import Container from "./container";
import { imageUrlFor } from "../lib/image-url";
import { cn, buildImageObj, getPartnerUrl, getShowcaseCategoryUrl } from "../lib/helpers";
import Svg from 'react-inlinesvg';
import { Link } from "gatsby";
import Icon from "./icon";
//import Fancybox from "./fancybox";
import loadable from '@loadable/component';

const Fancybox = loadable(() => import('./fancybox'));

import * as styles from "../styles/components/showcase-page-header.module.css";

const PageHeader = (props) => {

  const {
    title,
    category,
    button,
    partner,
    client,
    image
  } = props;

  return (
    <div className={styles.root}>
      <div className={cn("grid", styles.grid)}>
        <div className={cn("col-12 col-lg-6", styles.text)}>
          <Container>
            <div className={styles.contentWrap}>
              {category && category.length > 0 && (
                <div className={styles.category}>
                  {category.slice(0, 1).map(({showcaseCategory, _key}) => (
                    <Link key={_key} to={getShowcaseCategoryUrl(showcaseCategory.slug)} className="h7">{showcaseCategory.title}</Link>
                  ))}
                </div>
              )}
              {title && (<h1 className={cn("h4", styles.title)}>{title}</h1>)}
              {button && button.url && (
                <Fancybox options={{ 
                  dragToClose: false,
                  Hash: false,
                }}>
                  <a href={button.url} className={cn("btn", styles.demo)} data-fancybox data-type="iframe" data-preload="false">
                    {button.text ? button.text : "View Demo"}<Icon symbol="arrow-right" />
                  </a>
                </Fancybox>
              )}
            </div>
          </Container>
        </div>
        <div className={cn("col-12 col-lg-6", styles.image)}>
          {image && image.asset && (
            <img
              src={imageUrlFor(buildImageObj(image))
                .auto("format")
                .url()}
              alt={image.alt}
            />
          )}
        </div>
        <div className={cn("col-12 col-lg-6", styles.partner)}>
          <Container>
            <div className={styles.label}>{partner && partner.type && partner.type.title + " "}Partner</div>
            {partner && partner.logo && partner.logo.asset && (
              <div className={styles.logo}>
                {partner.slug ? (
                  <Link to={getPartnerUrl(partner.slug)}>
                    {partner.logo.asset.extension === 'svg' ? (
                      <Svg 
                        src={partner.logo.asset.url} 
                        description={partner.logo.alt ? partner.logo.alt : partner.name}
                        uniquifyIDs={true}
                      />
                    ) : (
                      <img
                        src={imageUrlFor(buildImageObj(partner.logo))
                          .auto("format")
                          .url()}
                        alt={partner.logo.alt ? partner.logo.alt : partner.name}
                      />
                    )}
                  </Link>
                ) : (
                  <>
                    {partner.link ? (
                      <a href={partner.link} target="_blank" rel="noreferrer">
                        {partner.logo.asset.extension === 'svg' ? (
                          <Svg 
                            src={partner.logo.asset.url} 
                            description={partner.logo.alt ? partner.logo.alt : partner.name}
                            uniquifyIDs={true}
                          />
                        ) : (
                          <img
                            src={imageUrlFor(buildImageObj(partner.logo))
                              .auto("format")
                              .url()}
                            alt={partner.logo.alt ? partner.logo.alt : partner.name}
                          />
                        )}
                      </a>
                    ) : (
                      <>
                        {partner.logo.asset.extension === 'svg' ? (
                          <Svg 
                            src={partner.logo.asset.url} 
                            description={partner.logo.alt ? partner.logo.alt : partner.name}
                            uniquifyIDs={true}
                          />
                        ) : (
                          <img
                            src={imageUrlFor(buildImageObj(partner.logo))
                              .auto("format")
                              .url()}
                            alt={partner.logo.alt ? partner.logo.alt : partner.name}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </Container>
        </div>
        <div className={cn("col-12 col-lg-6", styles.client)}>
          <Container>
            <div className={styles.label}>Client</div>
            {client && client.logo && client.logo.asset && (
              <div className={styles.logo}>
                {client.link ? (
                  <a href={client.link} target="_blank" rel="noreferrer">
                    {client.logo.asset.extension === 'svg' ? (
                      <Svg 
                        src={client.logo.asset.url} 
                        description={client.logo.alt ? client.logo.alt : client.name}
                        uniquifyIDs={true}
                      />
                    ) : (
                      <img
                        src={imageUrlFor(buildImageObj(client.logo))
                          .auto("format")
                          .url()}
                        alt={client.logo.alt ? client.logo.alt : client.name}
                      />
                    )}
                  </a>
                ) : (
                  <>
                    {client.logo.asset.extension === 'svg' ? (
                      <Svg 
                        src={client.logo.asset.url} 
                        description={client.logo.alt ? client.logo.alt : client.name}
                        uniquifyIDs={true}
                      />
                    ) : (
                      <img
                        src={imageUrlFor(buildImageObj(client.logo))
                          .auto("format")
                          .url()}
                        alt={client.logo.alt ? client.logo.alt : client.name}
                      />
                    )}
                  </>
                )}
              </div>
            )}
          </Container>
        </div>
      </div>
    </div>
  );
}

export default PageHeader;
